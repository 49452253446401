import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../auth/AuthContext";
import WhoWeAre from "../assets/barangay-about/noimage.png";
import { AnimatedPage } from "../FramerMotion/AnimatedPage";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Carousel } from "react-responsive-carousel";
import { Footer } from "../../layouts/Footer";


function Cabigaan() {
    const { userRole } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState("about");
    const barangay_id = localStorage.getItem('barangay_id');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [approvedNews, setApprovedNews] = useState([]);
    const [newsLoading, setNewsLoading] = useState(true);
    const [newsError, setNewsError] = useState('');

    const role_id = localStorage.getItem('role_id');

    const fetchApprovedNews = async () => {
        try {
            const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/approved-news');
            if (!response.ok) {
                throw new Error('Failed to fetch approved news');
            }
            const data = await response.json();
            // Filter the news posts where barangay_id is 10
            const filteredNews = data.filter(post => post.barangay_id === 10);
            setApprovedNews(filteredNews);
        } catch (error) {
            setNewsError('Error fetching approved news.');
        } finally {
            setNewsLoading(false);
        }
    };

    useEffect(() => {
        fetchApprovedNews();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (title.length > 100) {
            alert('Title is too long! Maximum length is 100 characters.');
            return;
        }

        if (content.length > 6000) {
            alert('Content is too long! Maximum length is 1000 characters.');
            return;
        }

        if (file && !['image/jpeg', 'image/png', 'video/mp4'].includes(file.type)) {
            alert('Unsupported file type. Please upload an image or video.');
            return;
        }

        const userId = localStorage.getItem('authId');
        if (!userId) {
            alert('User not authenticated');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        formData.append('user_id', userId);
        if (file) {
            formData.append('image', file);
        }

        try {
            setLoading(true);
            const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/submit-news', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('News created successfully');
                setTitle('');
                setContent('');
                setFile(null);
                setError('');
                fetchApprovedNews();
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to create news. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };


    //MARKETPLACE
    const [product, setProduct] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [approvedProducts, setApprovedProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(true);



    // Fetch approved products on component mount
    useEffect(() => {
        const fetchApprovedProducts = async () => {
            try {
                const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/approved-products');
                if (!response.ok) throw new Error('Failed to fetch approved products');
                const data = await response.json();
                const filteredProducts = data.filter(product => product.barangay_id === 10);
                setApprovedProducts(filteredProducts);
            } catch (error) {
                setErrorMessage('Unable to load approved products.');
            } finally {
                setProductsLoading(false);
            }
        };

        fetchApprovedProducts();
    }, []);

    // Handle image uploads
    const handleImageChange = (e) => {
        setImages(Array.from(e.target.files)); // Convert FileList to Array
    };

    // Submit product form
    const handleSubmitProduct = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (!product || !description) {
            setErrorMessage('Product name and description are required.');
            return;
        }

        const formData = new FormData();
        formData.append('product', product);
        formData.append('description', description);

        images.forEach((image) => formData.append('images[]', image));

        try {
            setLoading(true);

            const userId = localStorage.getItem('authId');
            if (!userId) throw new Error('User not authenticated.');

            formData.append('user_id', userId);

            const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/sell-products', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) throw new Error('Failed to submit product.');

            setSuccessMessage('Product submitted successfully for approval.');
            setProduct('');
            setDescription('');
            setImages([]);

        } catch (error) {
            setErrorMessage(error.message || 'An error occurred while submitting the product.');
        } finally {
            setLoading(false);
        }

    };



    const renderContent = () => {
        switch (activeTab) {
            case "about":
                return (
                    <>
                        <div className='flex justify-end mb-8 text-lg lg:p-8 p-4 font-semibold'>
                            <span className='py-4 px-4 border border-2 border-green-500'>Brgy Emergency Hotline #</span>
                        </div>
                        <div className="flex flex-col md:flex-row items-center px-4 mb-32">
                            <div className="md:w-1/2 md:pr-5">
                                <img
                                    src={WhoWeAre}
                                    alt="Who We Are"
                                    className="w-full rounded-md shadow-lg"
                                />
                            </div>
                            <div className="md:w-1/2 lg:px-4">
                                <h2 className="text-4xl py-5 font-semibold">What is Cabigaan?</h2>
                                <p className="text-justify text-lg">
                                    Barangay Cabigaan had been a sitio of Bulacao. The sitio was headed by a "Segundo Teniente del Barrio" whose appointment came from the Teniente del Barrio of Bulacao was Leon Funelas, then a 60-year old resident and assistant to the Teniente del Barrio of Bulacao with respect to the administration of the sitio.
                                    In January 1952, Leon Funelas called a community assembly and there discussed various matters affecting their sitio. Among the thing approved and given priority attention was the establishment of their own Ermita, a center for religious activities which at that time is the dominant pre-occupation of the people. Not long after the community assembly, the barrio folks through self-help, constructed a barrio chapel to house their patron saint San Isidro.

                                    The construction of the barrio chapel and later the construction of a temporary school building, caused the concentration of regular activities in the sitio that they seldom go, if ever, to their mother barangay for their religious and other activities.

                                    A few months later, Mr. Eutiquio Esquillo donated a one-hectare lot to the sitio to be used as a school campus.

                                    The concentration of activities in their own sitio lead to the severance of this sitio from their mother barangay and eventually to its registration as a separate barangay.

                                    Barangay Cabigaan derived its name after a plant of the giant gabi family known locally as "Biga", which, at that time and even up to the present, thrived abundantly along the sides and banks of creeks. "Cabigaan" means a place Biga is abundant.


                                </p>
                                <div className="flex justify-end">
                                    <Link to="/barangays" className="py-4 px-8 bg-blue-500 mt-4 hover:bg-blue-900 text-white">
                                        See other barangays
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case "news":
                return (
                    <div className="flex-1 overflow-y-auto mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 mt-8 mb-32">
                        <h2 className="text-2xl pb-5 font-semibold">Announcements / News</h2>
                        {(barangay_id === '10') && (
                            <div className="rounded-xl bg-white p-8 shadow-lg mb-8">
                                <h3 className="mb-6 text-2xl font-semibold text-gray-800">Create Community Post</h3>

                                {/* Display error message if exists */}
                                {error && (
                                    <div className="mb-4 text-red-500">{error}</div>
                                )}

                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div>
                                        <label htmlFor="title" className="text-lg font-medium text-gray-700">News Title</label>
                                        <input
                                            type="text"
                                            id="title"
                                            name="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            className="mt-2 block w-full rounded-lg border border-gray-300 px-4 py-3 text-gray-800 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-400"
                                            placeholder="Enter a title for your news post"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="content" className="text-lg font-medium text-gray-700">News Content</label>
                                        {/* Use ReactQuill for rich text editing */}
                                        <ReactQuill
                                            id="content"
                                            value={content}
                                            onChange={setContent}
                                            className="mt-2 block w-full rounded-lg border border-gray-300 px-4 py-10 text-gray-800 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-400"
                                            placeholder="Write the content for your news post"
                                            theme="snow"
                                            required
                                        />
                                    </div>

                                    {/* File Upload Section */}
                                    <div>
                                        <label htmlFor="image" className="text-lg font-medium text-gray-700">Upload Image/Video</label>
                                        <div className="mt-2">
                                            <input
                                                type="file"
                                                id="image"
                                                name="image"
                                                accept="image/*,video/*"
                                                onChange={(e) => setFile(e.target.files[0])}
                                                className="block w-full text-gray-800 py-3 px-4 rounded-lg border border-gray-300 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-400"
                                            />
                                        </div>
                                        {file && (
                                            <div className="mt-2 text-sm text-gray-500">
                                                {file.name} - {file.size > 1024 * 1024 ? `${(file.size / (1024 * 1024)).toFixed(2)} MB` : `${(file.size / 1024).toFixed(2)} KB`}
                                            </div>
                                        )}
                                    </div>

                                    {/* Submit Button */}
                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="inline-flex items-center rounded-lg bg-indigo-600 px-6 py-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        >
                                            {loading ? 'Creating...' : 'Create News'}
                                        </button>
                                    </div>
                                </form>

                            </div>
                        )}
                        {newsLoading ? (
                            <div>Loading approved news...</div>
                        ) : newsError ? (
                            <div className="text-red-500">{newsError}</div>
                        ) : approvedNews.length === 0 ? (
                            <div className="text-gray-500 text-center mt-6">No available news.</div>
                        ) : (
                            <div className="space-y-6">
                                {approvedNews.map((news) => (
                                    <div
                                        key={news.id}
                                        className="rounded-lg bg-white p-6 shadow-sm hover:shadow-md transition-shadow duration-300 ease-in-out"
                                    >
                                        <p className="mt-2 text-sm text-gray-500 text-right">
                                            Created on: {new Date(news.created_at).toLocaleString()}
                                        </p>
                                        {news.image && (
                                            <div className="flex justify-center">
                                                <img
                                                    src={news.image}
                                                    alt={news.title}
                                                    className="mt-4 mb-4 lg:w-[1200px] lg:h-[600px] w-full object-stretch"
                                                />
                                            </div>
                                        )}
                                        <h4 className="mt-4 text-2xl font-semibold text-gray-800">{news.title}</h4>
                                        <div
                                            className="mt-2 text-gray-600 text-lg leading-relaxed"
                                            dangerouslySetInnerHTML={{ __html: news.content }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            case "marketplace":
                return (


                    <div className=' bg-[#F5EFE7] overflow-y-auto'>
                        <div className='h-screen flex flex-col justify-between'>
                            <div className="flex flex-col justify-between mx-auto lg:w-5/6 p-8">
                                {/* Sell Product Section */}

                                {(barangay_id === '9' && role_id === '2') && (
                                    <div className="bg-white p-6 rounded-xl shadow-lg mb-12">
                                        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Sell Barangay Product</h2>

                                        {errorMessage && <p className="text-red-600 text-center mb-4">{errorMessage}</p>}

                                        {successMessage && <p className="text-green-600 text-center mb-4">{successMessage}</p>}

                                        <form onSubmit={handleSubmitProduct} className="space-y-8">
                                            <div>
                                                <label htmlFor="product" className="block text-gray-700 font-medium">Product Name</label>
                                                <input
                                                    type="text"
                                                    id="product"
                                                    value={product}
                                                    onChange={(e) => setProduct(e.target.value)}
                                                    className="w-full mt-2 px-4 py-2 border rounded-xl focus:ring-indigo-500 focus:border-indigo-500"
                                                    placeholder="Enter product name"
                                                    required
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="description" className="block text-gray-700 font-medium">Product Description</label>
                                                <ReactQuill
                                                    id="description"
                                                    value={description}
                                                    onChange={setDescription}
                                                    className="mt-2 bg-white border rounded-xl"
                                                    placeholder="Enter product description"
                                                />
                                            </div>

                                            <div>
                                                <label htmlFor="images" className="block text-gray-700 font-medium">Upload Images</label>
                                                <input
                                                    type="file"
                                                    id="images"
                                                    multiple
                                                    accept="image/*"
                                                    onChange={handleImageChange}
                                                    className="w-full mt-2 px-4 py-2 border rounded-xl focus:ring-indigo-500 focus:border-indigo-500"
                                                />
                                                {images.length > 0 && (
                                                    <div className="mt-4 grid grid-cols-3 gap-4">
                                                        {images.map((image, index) => (
                                                            <div key={index} className="relative">
                                                                <img
                                                                    src={URL.createObjectURL(image)}
                                                                    alt={`Uploaded image ${index + 1}`}
                                                                    className="w-full h-32 object-cover rounded-xl"
                                                                />
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>

                                            <button
                                                type="submit"
                                                disabled={loading}
                                                className={`w-full px-4 py-3 text-white rounded-xl ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'}`}
                                            >
                                                {loading ? 'Submitting...' : 'Submit Product'}
                                            </button>
                                        </form>
                                    </div>
                                )}

                                {/* Approved Products Section */}
                                <div className='mb-16'>
                                    <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Barangay Marketplace</h2>
                                    {/* <p className="text-center">Explore products and services in the marketplace.</p> */}
                                    {productsLoading ? (
                                        <p className="text-center text-gray-600">Loading products...</p>
                                    ) : approvedProducts.length > 0 ? (
                                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                            {approvedProducts.map((product) => {
                                                const parsedImages = JSON.parse(product.images || '[]');
                                                return (
                                                    <div key={product.id} className="bg-white rounded-xl shadow-lg overflow-hidden">
                                                        <div className="p-4">
                                                            <h3 className="text-lg font-semibold text-gray-800">{product.product}</h3>
                                                            <div
                                                                className="text-gray-600 mt-2"
                                                                dangerouslySetInnerHTML={{ __html: product.description }}
                                                            ></div>

                                                            <p className="text-gray-600 mt-2">Created on: {new Date(product.created_at).toLocaleString()}</p>
                                                        </div>
                                                        <Carousel showThumbs={false} infiniteLoop autoPlay>
                                                            {parsedImages.map((image, index) => (
                                                                <div key={index}>
                                                                    <img
                                                                        src={image}
                                                                        alt={`Product image ${index + 1}`}
                                                                        className="w-full h-64 object-cover"
                                                                    />
                                                                </div>
                                                            ))}
                                                        </Carousel>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : (
                                        <p className="text-center text-gray-600">No products available.</p>
                                    )}
                                </div>

                            </div>
                            <div>
                                <Footer />
                            </div>

                        </div>

                    </div>

                );
            default:
                return null;
        }
    };

    return (
        <div className="h-screen flex flex-col bg-gray-100 font-poppins">
            {/* Tabs Navigation */}
            <div className="bg-[#3E5879] text-white flex justify-center md:justify-start p-3 md:p-5">
                <button
                    onClick={() => setActiveTab("about")}
                    className={`px-4 py-2 font-semibold ${activeTab === "about"
                        ? "border-b-4 border-white"
                        : "hover:text-gray-300"
                        }`}
                >
                    About
                </button>
                <button
                    onClick={() => setActiveTab("news")}
                    className={`px-4 py-2 font-semibold ${activeTab === "news"
                        ? "border-b-4 border-white"
                        : "hover:text-gray-300"
                        }`}
                >
                    News
                </button>
                <button
                    onClick={() => setActiveTab("marketplace")}
                    className={`px-4 py-2 font-semibold ${activeTab === "marketplace"
                        ? "border-b-4 border-white"
                        : "hover:text-gray-300"
                        }`}
                >
                    Marketplace
                </button>

                {role_id === null && (
                    <a
                        href="/login"
                        className="px-4 py-2 font-semibold"
                    >
                        Login
                    </a>
                )}


            </div>

            {/* Main Content */}

            <div className="flex-1 overflow-y-auto bg-[#F5EFE7]">
                <AnimatedPage>{renderContent()}</AnimatedPage>
            </div>
        </div>
    );
}

export default Cabigaan;
