import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../auth/AuthContext'; // Adjust path as necessary
import logoLeft from '../img/loginLogo.jpg'; // Adjust path as necessary
import photo1 from '../img/photo1.jpg'; // Adjust path as necessary
import photo2 from '../img/photo2.jpg'; // Adjust path as necessary
import logo from '../img/logo.png'; // Adjust path as necessary
import './login.css';
import { Link } from 'react-router-dom';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { login } = useContext(AuthContext);

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await login({ email, password });
        } catch (error) {
            console.error('Login failed', error);
        }
    };

    return (
        <div className="flex min-h-screen bg-[#FEFEFE] overflow-y-auto">

            {/* Right Side with Login Form */}
            <div className="w-full bg-image flex items-center justify-center bg-white p-8">
                <div className="hidden lg:block lg:w-1/2">
                </div>

                <div className="w-full lg:w-1/2 max-w-sm lg:max-w-lg">
                    <img src={logo} className="w-full" alt="" />
                    <form onSubmit={handleSubmit} className='px-14'>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2">Username</label>
                            <input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                                className="bg-gray-200 text-gray-700 border border-gray-600 rounded py-2 px-4 block w-full appearance-none focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <div className="mb-6">
                            <div className="flex justify-between items-center">
                                <label className="block text-gray-700 text-sm font-bold mb-2">Password</label>
                            </div>
                            <input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className=" bg-gray-200 text-gray-700 border border-gray-600 rounded py-2 px-4 block w-full appearance-none focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <button
                            type="submit"
                            className="bg-blue-900 text-white font-bold py-2 px-4 w-full rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-500"
                        >

                            Login
                        </button>
                        <Link to='/' className='flex justify-center mt-4 underline text-white lg:text-black'>Back to Home</Link>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default LoginPage;
