import { useState, useEffect } from 'react';
import ReactQuill from 'react-quill'; // Import Quill
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { Footer } from '../../layouts/Footer';

export default function CommunityNews() {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [approvedNews, setApprovedNews] = useState([]);
    const [newsLoading, setNewsLoading] = useState(true);
    const [newsError, setNewsError] = useState('');

    const role_id = localStorage.getItem('role_id');

    // Fetch approved news when the component mounts
    const fetchApprovedNews = async () => {
        try {
            const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/approved-news');
            if (!response.ok) {
                throw new Error('Failed to fetch approved news');
            }
            const data = await response.json();
            setApprovedNews(data);
            // console.log("Role ID:", typeof role_id);
        } catch (error) {
            setNewsError('Error fetching approved news.');
        } finally {
            setNewsLoading(false);
        }
    };

    useEffect(() => {
        fetchApprovedNews();
    }, []);

    // Handle form submission
    const handleSubmit = async (event) => {
        event.preventDefault();

        // Title and content length validation
        if (title.length > 100) {
            alert('Title is too long! Maximum length is 100 characters.');
            return;
        }

        if (content.length > 6000) {
            alert('Content is too long! Maximum length is 1000 characters.');
            return;
        }

        // File type validation
        if (file && !['image/jpeg', 'image/png', 'video/mp4'].includes(file.type)) {
            alert('Unsupported file type. Please upload an image or video.');
            return;
        }

        // Get userId from localStorage
        const userId = localStorage.getItem('authId');
        if (!userId) {
            alert('User not authenticated');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        formData.append('user_id', userId);

        if (file) {
            formData.append('image', file); // Corrected: using 'image' instead of 'file'
        }

        try {
            setLoading(true);
            const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/submit-news', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('News created successfully');
                // Reset form fields after successful submission
                setTitle('');
                setContent('');
                setFile(null);
                setError('');

                // Re-fetch approved news after successfully submitting the new post
                fetchApprovedNews();
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to create news. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="bg-[#F5EFE7] overflow-auto">
            <div className='h-screen flex flex-col justify-between'>
                <div className="flex flex-col justify-between mx-auto lg:w-4/6 p-8">
                    {(role_id === "1" || role_id === "2") && (
                        <div className="rounded-xl bg-white p-8 shadow-lg">
                            <h3 className="mb-6 text-2xl font-semibold text-gray-800">Create Community Post</h3>
                            {/* Display error message if exists */}
                            {error && (
                                <div className="mb-4 text-red-500">{error}</div>
                            )}

                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label htmlFor="title" className="text-lg font-medium text-gray-700">News Title</label>
                                    <input
                                        type="text"
                                        id="title"
                                        name="title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                        className="mt-2 block w-full rounded-lg border border-gray-300 px-4 py-3 text-gray-800 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-400"
                                        placeholder="Enter a title for your news post"
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="content" className="text-lg font-medium text-gray-700">News Content</label>
                                    {/* Use ReactQuill for rich text editing */}
                                    <ReactQuill
                                        id="content"
                                        value={content}
                                        onChange={setContent}
                                        className="mt-2 block w-full rounded-lg border border-gray-300 px-4 py-10 text-gray-800 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-400"
                                        placeholder="Write the content for your news post"
                                        theme="snow"
                                        required
                                    />
                                </div>

                                {/* File Upload Section */}
                                <div>
                                    <label htmlFor="image" className="text-lg font-medium text-gray-700">Upload Image/Video</label>
                                    <div className="mt-2">
                                        <input
                                            type="file"
                                            id="image"
                                            name="image"
                                            accept="image/*,video/*"
                                            onChange={(e) => setFile(e.target.files[0])}
                                            className="block w-full text-gray-800 py-3 px-4 rounded-lg border border-gray-300 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-400"
                                        />
                                    </div>
                                    {file && (
                                        <div className="mt-2 text-sm text-gray-500">
                                            {file.name} - {file.size > 1024 * 1024 ? `${(file.size / (1024 * 1024)).toFixed(2)} MB` : `${(file.size / 1024).toFixed(2)} KB`}
                                        </div>
                                    )}
                                </div>

                                {/* Submit Button */}
                                <div className="flex justify-end">
                                    <button
                                        type="submit"
                                        disabled={loading}
                                        className="inline-flex items-center rounded-lg bg-indigo-600 px-6 py-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                    >
                                        {loading ? 'Creating...' : 'Create News'}
                                    </button>
                                </div>
                            </form>

                        </div>
                    )}

                    {/* Approved News List */}
                    <div className="mt-12">
                        <h3 className="mb-6 text-2xl font-semibold text-gray-800 pl-4 lg:pl-0">Gubat Community News</h3>

                        {newsLoading ? (
                            <div>Loading approved news...</div>
                        ) : newsError ? (
                            <div className="text-red-500">{newsError}</div>
                        ) : (
                            <div className="space-y-6">
                                {approvedNews.map((news) => (
                                    <div key={news.id} className="rounded-lg bg-white p-6 shadow-md hover:shadow-xl transition-shadow duration-300 ease-in-out">

                                        <p className="mt-2 text-sm text-gray-500 text-right">Created on: {new Date(news.created_at).toLocaleString()}</p>
                                        {news.image && (
                                            <div className="flex justify-center">
                                                <img
                                                    src={news.image}
                                                    alt={news.title}
                                                    className="mt-4 mb-4 lg:w-[1200px] lg:h-[600px] w-full  object-stretch" // w-full for responsive width, and fixed height
                                                />
                                            </div>
                                        )}

                                        <h4 className="mt-4 text-2xl font-semibold text-gray-800">{news.title}</h4>

                                        {/* Render content as HTML */}
                                        <div className="mt-2 text-gray-600 text-lg leading-relaxed"
                                            dangerouslySetInnerHTML={{ __html: news.content }} />

                                    </div>
                                ))}
                            </div>
                        )}
                    </div>

                </div>
                <div>
                    <Footer />
                </div>


            </div>

        </div>
    );
}
