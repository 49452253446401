import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../../auth/AuthContext";
import WhoWeAre from "../assets/barangay-about/noimage.png";
import { AnimatedPage } from "../FramerMotion/AnimatedPage";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


function Panganiban() {
    const { userRole } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState("about");
    const barangay_id = localStorage.getItem('barangay_id');
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [approvedNews, setApprovedNews] = useState([]);
    const [newsLoading, setNewsLoading] = useState(true);
    const [newsError, setNewsError] = useState('');

    const role_id = localStorage.getItem('role_id');

    const fetchApprovedNews = async () => {
        try {
            const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/approved-news');
            if (!response.ok) {
                throw new Error('Failed to fetch approved news');
            }
            const data = await response.json();
            // Filter the news posts where barangay_id is 14
            const filteredNews = data.filter(post => post.barangay_id === 27);
            setApprovedNews(filteredNews);
        } catch (error) {
            setNewsError('Error fetching approved news.');
        } finally {
            setNewsLoading(false);
        }
    };

    useEffect(() => {
        fetchApprovedNews();
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (title.length > 100) {
            alert('Title is too long! Maximum length is 100 characters.');
            return;
        }

        if (content.length > 6000) {
            alert('Content is too long! Maximum length is 1000 characters.');
            return;
        }

        if (file && !['image/jpeg', 'image/png', 'video/mp4'].includes(file.type)) {
            alert('Unsupported file type. Please upload an image or video.');
            return;
        }

        const userId = localStorage.getItem('authId');
        if (!userId) {
            alert('User not authenticated');
            return;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        formData.append('user_id', userId);
        if (file) {
            formData.append('image', file);
        }

        try {
            setLoading(true);
            const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/submit-news', {
                method: 'POST',
                body: formData,
            });

            if (response.ok) {
                console.log('News created successfully');
                setTitle('');
                setContent('');
                setFile(null);
                setError('');
                fetchApprovedNews();
            } else {
                const errorData = await response.json();
                setError(errorData.message || 'Failed to create news. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setError('An error occurred. Please try again later.');
        } finally {
            setLoading(false);
        }
    };



    const renderContent = () => {
        switch (activeTab) {
            case "about":
                return (
                    <>
                        <div className='flex justify-end mb-8 text-lg lg:p-8 p-4 font-semibold'>
                            <span className='py-4 px-4 border border-2 border-green-500'>Brgy Emergency Hotline #</span>
                        </div>
                        <div className="flex flex-col md:flex-row items-center px-4 mb-32">
                            <div className="md:w-1/2 md:pr-5">
                                <img
                                    src={WhoWeAre}
                                    alt="Who We Are"
                                    className="w-full rounded-md shadow-lg"
                                />
                            </div>
                            <div className="md:w-1/2 lg:px-4">
                                <h2 className="text-4xl py-5 font-semibold">What is Panganiban?</h2>
                                <p className="text-justify text-lg">


                                    People used to call the place "Dancalan" because of its abundance of dancalan trees that use to dot along the lines near the shore. In 1950 it was declared a barrio with Pio Esperida as the first Teniente del Barrio. Later on, it was referred to a purok when only 33 barrios were officially registered. The poblacion barangay were all called "puroks".

                                    By virtue of R.A. No. 3590, the place was declared as a barrio and it used Panganiban a Bicolano World War II hero. The barangay came to life in the ealy 50s during the Magsaysay regime. Panganbian was the site of a rock causeway aimed at converting the twon into a major seaport. In 1968, however, after years of construction and waiting for the first big ship to call, Typhoon Welming came and pounded repeatedly the structure. It was destroyed partially but it succumb to more and stronger typhoons that visited Gubat later on.

                                </p>
                                <div className="flex justify-end">
                                    <Link to="/barangays" className="py-4 px-8 bg-blue-500 mt-4 hover:bg-blue-900 text-white">
                                        See other barangays
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                );
            case "news":
                return (
                    <div className="flex-1 overflow-y-auto mx-auto max-w-7xl py-6 sm:px-6 lg:px-8 mt-8 mb-32">
                        <h2 className="text-2xl pb-5 font-semibold">Announcements / News</h2>
                        {(barangay_id === '27') && (
                            <div className="rounded-xl bg-white p-8 shadow-lg mb-8">
                                <h3 className="mb-6 text-2xl font-semibold text-gray-800">Create Community Post</h3>

                                {/* Display error message if exists */}
                                {error && (
                                    <div className="mb-4 text-red-500">{error}</div>
                                )}

                                <form onSubmit={handleSubmit} className="space-y-6">
                                    <div>
                                        <label htmlFor="title" className="text-lg font-medium text-gray-700">News Title</label>
                                        <input
                                            type="text"
                                            id="title"
                                            name="title"
                                            value={title}
                                            onChange={(e) => setTitle(e.target.value)}
                                            className="mt-2 block w-full rounded-lg border border-gray-300 px-4 py-3 text-gray-800 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-400"
                                            placeholder="Enter a title for your news post"
                                            required
                                        />
                                    </div>

                                    <div>
                                        <label htmlFor="content" className="text-lg font-medium text-gray-700">News Content</label>
                                        {/* Use ReactQuill for rich text editing */}
                                        <ReactQuill
                                            id="content"
                                            value={content}
                                            onChange={setContent}
                                            className="mt-2 block w-full rounded-lg border border-gray-300 px-4 py-10 text-gray-800 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-400"
                                            placeholder="Write the content for your news post"
                                            theme="snow"
                                            required
                                        />
                                    </div>

                                    {/* File Upload Section */}
                                    <div>
                                        <label htmlFor="image" className="text-lg font-medium text-gray-700">Upload Image/Video</label>
                                        <div className="mt-2">
                                            <input
                                                type="file"
                                                id="image"
                                                name="image"
                                                accept="image/*,video/*"
                                                onChange={(e) => setFile(e.target.files[0])}
                                                className="block w-full text-gray-800 py-3 px-4 rounded-lg border border-gray-300 shadow-sm focus:border-indigo-600 focus:ring-2 focus:ring-indigo-400"
                                            />
                                        </div>
                                        {file && (
                                            <div className="mt-2 text-sm text-gray-500">
                                                {file.name} - {file.size > 1024 * 1024 ? `${(file.size / (1024 * 1024)).toFixed(2)} MB` : `${(file.size / 1024).toFixed(2)} KB`}
                                            </div>
                                        )}
                                    </div>

                                    {/* Submit Button */}
                                    <div className="flex justify-end">
                                        <button
                                            type="submit"
                                            disabled={loading}
                                            className="inline-flex items-center rounded-lg bg-indigo-600 px-6 py-3 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                                        >
                                            {loading ? 'Creating...' : 'Create News'}
                                        </button>
                                    </div>
                                </form>

                            </div>
                        )}
                        {newsLoading ? (
                            <div>Loading approved news...</div>
                        ) : newsError ? (
                            <div className="text-red-500">{newsError}</div>
                        ) : approvedNews.length === 0 ? (
                            <div className="text-gray-500 text-center mt-6">No available news.</div>
                        ) : (
                            <div className="space-y-6">
                                {approvedNews.map((news) => (
                                    <div
                                        key={news.id}
                                        className="rounded-lg bg-white p-6 shadow-sm hover:shadow-md transition-shadow duration-300 ease-in-out"
                                    >
                                        <p className="mt-2 text-sm text-gray-500 text-right">
                                            Created on: {new Date(news.created_at).toLocaleString()}
                                        </p>
                                        {news.image && (
                                            <div className="flex justify-center">
                                                <img
                                                    src={news.image}
                                                    alt={news.title}
                                                    className="mt-4 mb-4 lg:w-[1200px] lg:h-[600px] w-full object-stretch"
                                                />
                                            </div>
                                        )}
                                        <h4 className="mt-4 text-2xl font-semibold text-gray-800">{news.title}</h4>
                                        <div
                                            className="mt-2 text-gray-600 text-lg leading-relaxed"
                                            dangerouslySetInnerHTML={{ __html: news.content }}
                                        />
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                );
            case "marketplace":
                return (
                    <div className="p-5 bg-[#F5EFE7]">
                        <h2 className="text-4xl pb-5 font-semibold">Marketplace</h2>
                        <p>Explore products and services in the marketplace.</p>
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="h-screen flex flex-col bg-gray-100 font-poppins">
            {/* Tabs Navigation */}
            <div className="bg-[#3E5879] text-white flex justify-center md:justify-start p-3 md:p-5">
                <button
                    onClick={() => setActiveTab("about")}
                    className={`px-4 py-2 font-semibold ${activeTab === "about"
                        ? "border-b-4 border-white"
                        : "hover:text-gray-300"
                        }`}
                >
                    About
                </button>
                <button
                    onClick={() => setActiveTab("news")}
                    className={`px-4 py-2 font-semibold ${activeTab === "news"
                        ? "border-b-4 border-white"
                        : "hover:text-gray-300"
                        }`}
                >
                    News
                </button>
                <button
                    onClick={() => setActiveTab("marketplace")}
                    className={`px-4 py-2 font-semibold ${activeTab === "marketplace"
                        ? "border-b-4 border-white"
                        : "hover:text-gray-300"
                        }`}
                >
                    Marketplace
                </button>

                {role_id === null && (
                    <a
                        href="/login"
                        className="px-4 py-2 font-semibold"
                    >
                        Login
                    </a>
                )}


            </div>

            {/* Main Content */}

            <div className="flex-1 overflow-y-auto bg-[#F5EFE7]">
                <AnimatedPage>{renderContent()}</AnimatedPage>
            </div>
        </div>
    );
}

export default Panganiban;
