import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Footer } from '../../layouts/Footer';

const MarketplaceSell = () => {
    const [product, setProduct] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [approvedProducts, setApprovedProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(true);

    const role_id = localStorage.getItem('role_id');

    // Fetch approved products on component mount
    useEffect(() => {
        const fetchApprovedProducts = async () => {
            try {
                const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/approved-products');
                if (!response.ok) throw new Error('Failed to fetch approved products');
                const data = await response.json();
                setApprovedProducts(data);
            } catch (error) {
                setErrorMessage('Unable to load approved products.');
            } finally {
                setProductsLoading(false);
            }
        };

        fetchApprovedProducts();
    }, []);

    // Handle image uploads
    const handleImageChange = (e) => {
        setImages(Array.from(e.target.files)); // Convert FileList to Array
    };

    // Submit product form
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (!product || !description) {
            setErrorMessage('Product name and description are required.');
            return;
        }

        const formData = new FormData();
        formData.append('product', product);
        formData.append('description', description);

        images.forEach((image) => formData.append('images[]', image));

        try {
            setLoading(true);

            const userId = localStorage.getItem('authId');
            if (!userId) throw new Error('User not authenticated.');

            formData.append('user_id', userId);

            const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/sell-products', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) throw new Error('Failed to submit product.');

            setSuccessMessage('Product submitted successfully for approval.');
            setProduct('');
            setDescription('');
            setImages([]);

        } catch (error) {
            setErrorMessage(error.message || 'An error occurred while submitting the product.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className=' bg-[#F5EFE7] overflow-y-auto'>
            <div className='h-screen flex flex-col justify-between'>
                <div className="flex flex-col justify-between mx-auto lg:w-5/6 p-8">
                    {/* Sell Product Section */}

                    {(role_id === '1' || role_id === '2') && (
                        <div className="bg-white p-6 rounded-xl shadow-lg mb-12">
                            <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Sell Barangay Product</h2>

                            {errorMessage && <p className="text-red-600 text-center mb-4">{errorMessage}</p>}

                            {successMessage && <p className="text-green-600 text-center mb-4">{successMessage}</p>}

                            <form onSubmit={handleSubmit} className="space-y-8">
                                <div>
                                    <label htmlFor="product" className="block text-gray-700 font-medium">Product Name</label>
                                    <input
                                        type="text"
                                        id="product"
                                        value={product}
                                        onChange={(e) => setProduct(e.target.value)}
                                        className="w-full mt-2 px-4 py-2 border rounded-xl focus:ring-indigo-500 focus:border-indigo-500"
                                        placeholder="Enter product name"
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="description" className="block text-gray-700 font-medium">Product Description</label>
                                    <ReactQuill
                                        id="description"
                                        value={description}
                                        onChange={setDescription}
                                        className="mt-2 bg-white border rounded-xl"
                                        placeholder="Enter product description"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="images" className="block text-gray-700 font-medium">Upload Images</label>
                                    <input
                                        type="file"
                                        id="images"
                                        multiple
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="w-full mt-2 px-4 py-2 border rounded-xl focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                    {images.length > 0 && (
                                        <div className="mt-4 grid grid-cols-3 gap-4">
                                            {images.map((image, index) => (
                                                <div key={index} className="relative">
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        alt={`Uploaded image ${index + 1}`}
                                                        className="w-full h-32 object-cover rounded-xl"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <button
                                    type="submit"
                                    disabled={loading}
                                    className={`w-full px-4 py-3 text-white rounded-xl ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'}`}
                                >
                                    {loading ? 'Submitting...' : 'Submit Product'}
                                </button>
                            </form>
                        </div>
                    )}

                    {/* Approved Products Section */}
                    <div className='mb-16'>
                        <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Gubat Marketplace</h2>

                        {productsLoading ? (
                            <p className="text-center text-gray-600">Loading approved products...</p>
                        ) : approvedProducts.length > 0 ? (
                            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                {approvedProducts.map((product) => {
                                    const parsedImages = JSON.parse(product.images || '[]');
                                    return (
                                        <div key={product.id} className="flex-col items-between bg-white rounded-xl shadow-lg overflow-hidden">
                                            <div className="p-4">
                                                <h3 className="text-lg font-semibold text-gray-800">{product.product}</h3>
                                                <div
                                                    className="text-gray-600 mt-2"
                                                    dangerouslySetInnerHTML={{ __html: product.description }}
                                                ></div>

                                                <p className="text-gray-600 mt-2">Created on: {new Date(product.created_at).toLocaleString()}</p>
                                            </div>
                                            <div>
                                                <Carousel
                                                    showThumbs={true}
                                                    infiniteLoop
                                                    autoPlay
                                                    renderThumbs={() =>
                                                        parsedImages.map((image, index) => (
                                                            <img
                                                                key={index}
                                                                src={image}
                                                                alt={`Thumbnail ${index + 1}`}
                                                                className="w-16 h-16 object-cover"
                                                            />
                                                        ))
                                                    }
                                                >
                                                    {parsedImages.map((image, index) => (
                                                        <div key={index}>
                                                            <img
                                                                src={image}
                                                                alt={`Product image ${index + 1}`}
                                                                className="w-full h-64 object-cover"
                                                            />
                                                        </div>
                                                    ))}
                                                </Carousel>
                                            </div>

                                        </div>
                                    );
                                })}
                            </div>
                        ) : (
                            <p className="text-center text-gray-600">No approved products available.</p>
                        )}
                    </div>

                </div>
                <div>
                    <Footer />
                </div>

            </div>

        </div>

    );
};

export default MarketplaceSell;