import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './header.css';


export function MobileHeader() {
    const [isOpen, setIsOpen] = useState(false);

    const role_id = localStorage.getItem('role_id');
    



    return (
        <header className="w-full bg-[#D8C4B6] py-5 px-6 sm:hidden">
            <div className="flex items-center justify-between">
                <a href="index.html" className="text-blue-900 text-3xl font-semibold pacifico-regular hover:text-gray-300">Gubat One Community</a>
                <button onClick={() => setIsOpen(!isOpen)} className="text-white text-3xl focus:outline-none">
                    {!isOpen ? (
                        <i className="fas fa-bars"></i>
                    ) : (
                        <i className="fas fa-times"></i>
                    )}
                </button>
            </div>

            {/* Dropdown Nav */}
            <nav className={`flex flex-col pt-4 ${isOpen ? 'flex' : 'hidden'}`}>
                <Link to='/' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    Home
                </Link>
                <Link to='/about' className="flex items-center text-black py-2 pl-4 nav-item">
                    About
                </Link>
                
                <Link to='/barangays' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    Barangays
                </Link>
                <Link to='/news' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    Community News
                </Link>
                {role_id === '3' && (
                    <Link to='/sellers' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    Sellers
                </Link>
                )}
                <Link to='/marketplace' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    Barangay Marketplace
                </Link>
                <Link to='/magsasaka' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    Magsasaka
                </Link>
                <Link to='/b-games' className="flex items-center text-black opacity-75 hover:opacity-100 py-2 pl-4 nav-item">
                    {/* <i className="fas fa-align-left mr-3"></i> */}
                    B-Games
                </Link>
                {role_id !== null && (
                    <div className='flex justify-start ml-4 py-2 opacity-75'>
                        <button>
                            Log out
                        </button>
                    </div>
                )}



            </nav>
        </header>
    );
}

export default MobileHeader;
