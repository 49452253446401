import React, { useState, useContext } from 'react';
import CompanyLogo from '../img/logo.png'; // Assuming this is the correct logo import
import { Outlet, Link } from 'react-router-dom';
import MobileHeader from './MobileHeader';
import { AuthContext } from '../auth/AuthContext'; // Import AuthContext
import '../output.css';
import './header.css';
import '../components/assets/pages/css/home.css';
// import { Footer } from './Footer';


const Header = () => {
    const [activeLink, setActiveLink] = useState('/'); // State for active link
    const [isOpen, setIsOpen] = useState(false);
    const { logout } = useContext(AuthContext); // Get logout function from AuthContext
    const userName = localStorage.getItem('authName') || 'User';
    const { userRole } = useContext(AuthContext); // Access userRole from context


    const role_id = localStorage.getItem('role_id');

    console.log(role_id);

    // Function to handle logout
    const handleLogout = () => {
        logout(); // Call logout function from AuthContext
        // You might want to redirect or perform additional actions here
    };



    const linkClass = (path) =>
        `relative flex text-xl urbanist-400 font-semibold items-center py-4 px-6 transition-all ${activeLink === path ? 'text-blue-900' : 'text-gray-800'
        }`;

    const underlineClass = (path) =>
        `absolute bottom-0 left-0 h-[2px] transition-all ${activeLink === path
            ? 'w-full bg-blue-900'
            : 'w-0 bg-transparent hover:w-full hover:bg-blue-900'
        }`;

    return (
        <div className="relative w-full flex flex-col h-screen overflow-y-hidden">
            {/* Desktop Header */}
            <div className="w-full items-center bg-[#D8C4B6] py-3 px-6 hidden sm:flex">
                <div className="w-1/3 flex items-center">
                    <Link to='/' className='flex items-center'>
                        <img src={CompanyLogo} alt="Company Logo" className='h-[60px] mr-2' />
                        <h1 className='text-2xl font-bold text-indigo-800 pacifico-regular'>
                            Gubat One Community
                        </h1>
                    </Link>
                </div>
                <div className="relative w-2/3 flex justify-end items-center">

                    <Link
                        to="/"
                        onClick={() => setActiveLink('/')}
                        className={linkClass('/')}
                    >
                        Home
                        <span className={underlineClass('/')}></span>
                    </Link>
                    <Link
                        to="/about"
                        onClick={() => setActiveLink('/about')}
                        className={linkClass('/about')}
                    >
                        About
                        <span className={underlineClass('/about')}></span>
                    </Link>
                    <Link
                        to="/barangays"
                        onClick={() => setActiveLink('/barangays')}
                        className={linkClass('/barangays')}
                    >
                        Barangays
                        <span className={underlineClass('/barangays')}></span>
                    </Link>
                    {(role_id === '3' || role_id === null ) && (
                        <Link
                            to="/sellers"
                            onClick={() => setActiveLink('/sellers')}
                            className={linkClass('/sellers')}
                        >
                            Sellers
                            <span className={underlineClass('/sellers')}></span>
                        </Link>
                    )}

                    <Link
                        to="/news"
                        onClick={() => setActiveLink('/news')}
                        className={linkClass('/news')}
                    >
                        Community News
                        <span className={underlineClass('/news')}></span>
                    </Link>
                    <Link
                        to="/marketplace"
                        onClick={() => setActiveLink('/marketplace')}
                        className={linkClass('/marketplace')}
                    >
                        Barangay Marketplace
                        <span className={underlineClass('/marketplace')}></span>
                    </Link>
                    <Link
                        to="/magsasaka"
                        onClick={() => setActiveLink('/magsasaka')}
                        className={linkClass('/magsasaka')}
                    >
                        Magsasaka
                        <span className={underlineClass('/magsasaka')}></span>
                    </Link>
                    <Link
                        to="/b-games"
                        onClick={() => setActiveLink('/b-games')}
                        className={linkClass('/b-games')}
                    >
                        B-Games
                        <span className={underlineClass('/b-games')}></span>
                    </Link>


                    {(role_id !== null) && (
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="relative z-20 w-12 h-12 rounded-full overflow-hidden border-4 border-green-600 hover:border-green-800 focus:border-green-800 focus:outline-none"
                            aria-label="Toggle Menu"
                        >
                            <img src={CompanyLogo} alt="Menu Icon" />
                        </button>
                    )}
                    {isOpen && (
                        <>

                            <button
                                onClick={() => setIsOpen(false)}
                                className="h-full w-full fixed inset-0 cursor-default"
                                aria-label="Close Menu"
                            ></button>


                            <div className="absolute w-32 bg-white rounded-lg shadow-lg py-2 mt-32 z-10">
                                {(userRole === 1) && (
                                    <Link to='/create-user' className="block px-4 py-2 hover:text-green-800">Account</Link>
                                )}
                                <button
                                    onClick={handleLogout}
                                    className="block px-4 py-2 w-full text-left hover:text-green-800"
                                >
                                    Sign Out
                                </button>
                            </div>
                        </>

                    )}
                </div>
            </div>
            <MobileHeader />
            <Outlet />
            {/* <Footer /> */}
        </div>
    );
};

export default Header;
