import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

// Create the AuthContext
export const AuthContext = createContext();

// Create the AuthProvider component
export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [authId, setAuthId] = useState('');
  const navigate = useNavigate();

  // Set the base URL for Axios
  axios.defaults.baseURL = 'https://peachpuff-squid-437405.hostingersite.com';

  // Set the Authorization header
  const setAuthToken = (token) => {
    if (token) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete axios.defaults.headers.common['Authorization'];
    }
  };

  // Check authentication status
  const checkAuth = useCallback(async () => {
    const token = localStorage.getItem('authToken');
    setAuthToken(token);
    const userId = localStorage.getItem('id');
    setAuthId(userId);

    if (token) {
      try {
        const response = await axios.get('/api/user');
        setIsAuthenticated(true);
        setUserRole(response.data.role_id);
      } catch (error) {
        console.error('Check auth error:', error);
        setIsAuthenticated(false);
        setUserRole(null);
      }
    } else {
      setIsAuthenticated(false);
      setUserRole(null);
    }
    setLoading(false);
  }, []);

  // Use effect to check authentication on component mount
  useEffect(() => {
    checkAuth();
  }, [checkAuth]);

  // Login function
  const login = async (credentials) => {
    try {
        // console.log('Login attempt with credentials:', credentials);

        // Send login credentials to the API
        const response = await axios.post('/api/login', credentials);
        // console.log('Login response:', response.data);

        const { token } = response.data; // Expecting token from the response

        // Check if token is present
        if (!token) {
            throw new Error('Login failed: Missing token');
        }

        // console.log('Retrieved token:', token);
        localStorage.setItem('authToken', token); // Store token in localStorage

        // Set authentication token for future requests
        setAuthToken(token);
        setIsAuthenticated(true);

        // Fetch user role after login
        const userResponse = await axios.get('/api/user');
        // console.log('User response:', userResponse.data);

        const userId = userResponse.data.id;
        const userRole = userResponse.data.role_id;
        const userName = userResponse.data.name;
        const barangay_id = userResponse.data.barangay_id;

        // Check if userId and role are present
        if (!userId || !userRole) {
            throw new Error('Login failed: Missing userId or role_id');
        }

        // console.log('Retrieved userId:', userId);
        // console.log('Retrieved userRole:', userRole);
        // console.log('Retrieved userName:', userName);


        localStorage.setItem('authName', userName); // Store userName in localStorage
        localStorage.setItem('authId', userId); // Store userId in localStorage
        localStorage.setItem('role_id',userRole);
        localStorage.setItem('barangay_id',barangay_id);
        setUserRole(userRole);

        

        // Redirect to home page
        navigate('/barangays');
    } catch (error) {
        console.error('Login error:', error.message || error);
        setIsAuthenticated(false);
        setUserRole(null);
    }
};



  // Logout function
  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('role_id');
    setAuthToken(null);
    setIsAuthenticated(false);
    setUserRole(null);
    navigate('/login'); // Redirect after logout
  };

  // Provide context values
  return (
    <AuthContext.Provider value={{ isAuthenticated, loading, login, logout, userRole }}>
      {children}
    </AuthContext.Provider>
  );
};
