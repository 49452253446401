import React, { useState, useEffect } from 'react';
import LoginPage from "../LoginPage";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Footer } from '../../layouts/Footer';
const Seller = () => {
    const [product, setProduct] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [approvedProducts, setApprovedProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(true);

    const role_id = localStorage.getItem('role_id');



    // Fetch approved products on component mount
    useEffect(() => {
        const fetchApprovedProducts = async () => {
            try {
                const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/approved-products');
                if (!response.ok) throw new Error('Failed to fetch approved products');
                const data = await response.json();
                setApprovedProducts(data);
            } catch (error) {
                setErrorMessage('Unable to load approved products.');
            } finally {
                setProductsLoading(false);
            }
        };

        fetchApprovedProducts();
    }, []);

    // Handle image uploads
    const handleImageChange = (e) => {
        setImages(Array.from(e.target.files)); // Convert FileList to Array
    };

    // Submit product form
    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrorMessage('');
        setSuccessMessage('');

        if (!product || !description) {
            setErrorMessage('Product name and description are required.');
            return;
        }

        const formData = new FormData();
        formData.append('product', product);
        formData.append('description', description);

        images.forEach((image) => formData.append('images[]', image));

        try {
            setLoading(true);

            const userId = localStorage.getItem('authId');
            if (!userId) throw new Error('User not authenticated.');

            formData.append('user_id', userId);

            const response = await fetch('https://peachpuff-squid-437405.hostingersite.com/api/sell-products', {
                method: 'POST',
                body: formData,
            });

            if (!response.ok) throw new Error('Failed to submit product.');

            setSuccessMessage('Product submitted successfully for approval.');
            setProduct('');
            setDescription('');
            setImages([]);

        } catch (error) {
            setErrorMessage(error.message || 'An error occurred while submitting the product.');
        } finally {
            setLoading(false);
        }
    };

    if (role_id !== '3') {
        return LoginPage(); // Render nothing if the user is not a seller
    }



    return (
        // <div className="min-h-screen flex items-center justify-center bg-[#F5EFE7]">
        //     <div className="w-full max-w-md bg-white shadow-md rounded-lg p-6">
        //         <h1 className="text-2xl font-bold text-center mb-6 text-gray-800">Login As Seller</h1>
        //         <form onSubmit={handleLogin} className="space-y-4">
        //             <div>
        //                 <label
        //                     htmlFor="email"
        //                     className="block text-sm font-medium text-gray-700"
        //                 >
        //                     Email
        //                 </label>
        //                 <input
        //                     type="email"
        //                     id="email"
        //                     className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        //                     value={email}
        //                     onChange={(e) => setEmail(e.target.value)}
        //                     required
        //                 />
        //             </div>
        //             <div>
        //                 <label
        //                     htmlFor="password"
        //                     className="block text-sm font-medium text-gray-700"
        //                 >
        //                     Password
        //                 </label>
        //                 <input
        //                     type="password"
        //                     id="password"
        //                     className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
        //                     value={password}
        //                     onChange={(e) => setPassword(e.target.value)}
        //                     required
        //                 />
        //             </div>
        //             <div className="flex items-center justify-between">
        //                 <div>
        //                     <label className="inline-flex items-center">
        //                         <input
        //                             type="checkbox"
        //                             className="rounded text-blue-600 focus:ring-blue-500"
        //                         />
        //                         <span className="ml-2 text-sm text-gray-600">
        //                             Remember me
        //                         </span>
        //                     </label>
        //                 </div>
        //                 <a href="/forgot-password" className="text-sm text-blue-600 hover:underline">
        //                     Forgot password?
        //                 </a>
        //             </div>
        //             <button
        //                 type="submit"
        //                 className="w-full bg-blue-600 text-white py-2 px-4 rounded-md shadow hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-1"
        //             >
        //                 Login
        //             </button>
        //         </form>
        //         <p className="mt-6 text-center text-sm text-gray-600">
        //             Don't have an account?{" "}
        //             <a href="/sign-up" className="text-blue-600 hover:underline">
        //                 Sign up
        //             </a>
        //         </p>
        //     </div>
        // </div>
        <div className='flex flex-col justify-between h-screen overflow-y-auto'>
            <div className='flex justify-center bg-[#F5EFE7] '>
                <div className='w-5/6 lg:w-2/3 mt-12'>
                    {(role_id === '3') && (
                        <div className="bg-white p-6 rounded-xl shadow-lg mb-12">
                            <h2 className="text-3xl font-semibold text-gray-800 mb-6 text-center">Sell Product/s</h2>

                            {errorMessage && <p className="text-red-600 text-center mb-4">{errorMessage}</p>}

                            {successMessage && <p className="text-green-600 text-center mb-4">{successMessage}</p>}

                            <form onSubmit={handleSubmit} className="space-y-8">
                                <div>
                                    <label htmlFor="product" className="block text-gray-700 font-medium">Product Name</label>
                                    <input
                                        type="text"
                                        id="product"
                                        value={product}
                                        onChange={(e) => setProduct(e.target.value)}
                                        className="w-full mt-2 px-4 py-2 border rounded-xl focus:ring-indigo-500 focus:border-indigo-500"
                                        placeholder="Enter product name"
                                        required
                                    />
                                </div>

                                <div>
                                    <label htmlFor="description" className="block text-gray-700 font-medium">Product Description</label>
                                    <ReactQuill
                                        id="description"
                                        value={description}
                                        onChange={setDescription}
                                        className="mt-2 bg-white border rounded-xl"
                                        placeholder="Enter product description"
                                    />
                                </div>

                                <div>
                                    <label htmlFor="images" className="block text-gray-700 font-medium">Upload Images</label>
                                    <input
                                        type="file"
                                        id="images"
                                        multiple
                                        accept="image/*"
                                        onChange={handleImageChange}
                                        className="w-full mt-2 px-4 py-2 border rounded-xl focus:ring-indigo-500 focus:border-indigo-500"
                                    />
                                    {images.length > 0 && (
                                        <div className="mt-4 grid grid-cols-3 gap-4">
                                            {images.map((image, index) => (
                                                <div key={index} className="relative">
                                                    <img
                                                        src={URL.createObjectURL(image)}
                                                        alt={`Uploaded image ${index + 1}`}
                                                        className="w-full h-32 object-cover rounded-xl"
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <button
                                    type="submit"
                                    disabled={loading}
                                    className={`w-full px-4 py-3 text-white rounded-xl ${loading ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-600 hover:bg-indigo-700'}`}
                                >
                                    {loading ? 'Submitting...' : 'Post this Product to Marketplace'}
                                </button>
                            </form>
                        </div>
                    )}
                    
                </div>

            </div>
            <div>
                <Footer />
            </div>

        </div>

    );
};

export default Seller;
